import withWindowDimensions from 'components/common/withWindowDimensions'
import Image from 'next/image'
import React from 'react'
import Carousel, { Settings } from 'react-slick'
import { trackClick } from 'scripts/segment'
import { pageName } from 'utils/events-utils'

type Props = {
  isMobileSized?: boolean
}

const InfluencersSection: React.FunctionComponent<Props> = ({
  isMobileSized,
}) => {
  let customeSlider: any = null

  const settings: Settings = {
    dots: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 898,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
        },
      },
    ],
  }

  const previous = () => {
    customeSlider.slickNext()
  }

  const next = () => {
    customeSlider.slickPrev()
  }

  return (
    <div className="w-full bg-[#111430] p-[30px] md:p-[60px]">
      <div className="max-w-row mx-auto text-white">
        <div className="flex mb-8 items-center">
          <h2 className="text-[22px] md:text-[32px] font-bold grow text-center">
            Guest experts to make your application stand out
          </h2>
          <div className="hidden md:block pl-4">
            <div className="flex items-center">
              <img
                src= {`${process.env.CDN_URL}/assets/icons/left-icon.svg`}
                alt="left"
                className="mr-[34px] w-[25px] h-[25px]"
                onClick={() => next()}
              />
              <img
                src= {`${process.env.CDN_URL}/assets/icons/right-icon.svg`}
                alt="right"
                className="w-[25px] h-[25px]"
                onClick={() => previous()}
              />
            </div>
          </div>
        </div>

        <Carousel
          ref={(e) => {
            customeSlider = e
          }}
          {...settings}
        >
          {influencersData.map((elem: any, index: number) => (
            <div key={index}>
              <div className="w-[290px] h-[286px] md:w-[280px] md:h-[430px] mx-auto mb-[20px] md:mb-0">
                <Image
                  objectFit="cover"
                  key={index}
                  alt="influencers-image"
                  src={isMobileSized ? elem.mobileImg : elem.webImg}
                  height={isMobileSized ? 290 : 430}
                  width={isMobileSized ? 286 : 280}
                  style={{ borderRadius: '8px' }}
                />
              </div>
            </div>
          ))}
        </Carousel>

        <div className="flex justify-center mt-[32px] md:mt-[55px] items-center">
          <button
            className=" bg-primary px-5 py-3 md:px-8 flex rounded-lg"
            onClick={() => {
              trackClick(pageName.LeapScholarHomePage, {
                widgetName: 'Youtube Influencer Section',
                widgetFormat: 'Banner',
                contentName: 'Watch here',
                contentFormat: 'Button',
              })
              window.open(
                'https://www.youtube.com/playlist?list=PLJ8_iKTjNyEMiTn4uFqe7wCJz3jYNa87B',
                '_blank',
              )
            }}
          >
            <p className="text-lg md:text-[20px] pr-2">Watch here</p>{' '}
            <img
              className="w-[26px] md:w-[30px]"
              src= {`${process.env.CDN_URL}/assets/icons/right-white-icon.svg`}
              alt="icon"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default withWindowDimensions(InfluencersSection)

const influencersData = [
  {
    name: 'NIDHI NAGORI',
    followers: '700k+',
    specializedCountry: '🇨🇦 Canada',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_1_gI7vwjcEaR.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822698246',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_1_ijI8M7Arn.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169868',
  },
  {
    name: 'NAVJOT',
    followers: '190k+',
    specializedCountry: '🇨🇦 Canada',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_2_1w8Ajv7tdW.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822698271',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_2_0bsX8A75f.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169264',
  },
  {
    name: 'RAJEEV SANGWAN',
    followers: '105k+',
    specializedCountry: '🇺🇸 UK',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_7_FILrXYw4x.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822720536',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_7_UqojdkZani.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169941',
  },
  {
    name: 'TUSHAR BAREJA',
    followers: '387k+',
    specializedCountry: '🇦🇺 Australia',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_4_SAkebIONX.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822698231',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_4_5lpPIqcgPU.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169469',
  },
  {
    name: 'GURMEHAR KAUR',
    followers: '100k+',
    specializedCountry: '🇺🇸 UK',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_6_V7NZ5X0F4i.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822698233',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_6_MZdkznpJV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169276',
  },
  {
    name: 'SAM SHARMA',
    followers: '45k+',
    specializedCountry: '🇦🇺 Australia',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_5_yIS_yfgj67.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822698290',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_5_f851NXpIP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169195',
  },
  {
    name: 'SARIKA DUBEY',
    followers: '140k+',
    specializedCountry: '🇺🇸 UK',
    webImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_web_3_Gpl93Diyk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660822698243',
    mobileImg:
      'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/Influencers/influencers_mobile_3_LZJQx-9ph.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660827169886',
  },
]
